// @flow
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import Login from "../views/login/Login";

export default function ssoInit() {
  const reactContainer = document.getElementById("react-container");
  if (!reactContainer) {
    return;
  }
  render(<Login />, reactContainer);
}
