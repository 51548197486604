// @flow
import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthenticationStep from "./AuthenticationStep";
import EmailStep from "./EmailStep";

const history = createBrowserHistory({ basename: "/login" });

export default function Login() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact>
          <EmailStep />
        </Route>
        <Route path="/authentication">
          <AuthenticationStep />
        </Route>
      </Switch>
    </Router>
  );
}
