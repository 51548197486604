// @flow
import React, { useState } from "react";
import { useSpring, animated } from "react-spring/web.cjs.js";

type PasswordAuthenticationProps = {|
  email: string,
  toggleAuthenticationType?: ?() => void,
|};

export default function PasswordAuthentication({
  email,
  toggleAuthenticationType,
}: PasswordAuthenticationProps) {
  const style = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [password, setPassword] = useState<string>("");
  const csrfToken = document.querySelector('meta[name="csrf-token"]');

  return (
    <animated.div style={style}>
      <form
        className="ui form"
        action="/login"
        method="post"
        style={{
          minWidth: 300,
        }}
      >
        <input type="hidden" name="user[email]" value={email} />

        <div className="field ui left icon input">
          <input
            className="form-control"
            placeholder="password"
            autoFocus
            type="password"
            name="user[password]"
            value={password}
            onChange={(e: SyntheticInputEvent<HTMLInputElement>) =>
              setPassword(e.currentTarget.value)
            }
          />
          <i className="lock icon"></i>
        </div>

        <input
          type="hidden"
          name="authenticity_token"
          value={csrfToken ? csrfToken.getAttribute("content") : ""}
        />

        <div className="field">
          <button className="btn btn-blue">Login</button>
        </div>
      </form>
      {toggleAuthenticationType && (
        <p className="forgot">
          <a
            href="#"
            onClick={(e: SyntheticMouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              toggleAuthenticationType();
            }}
          >
            Log in using SSO
          </a>
        </p>
      )}
      <p className="forgot">
        <a href={window.forgotPasswordSettingsURL || "/password/new"}>
          Forgot password
        </a>
      </p>
    </animated.div>
  );
}
