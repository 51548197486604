/* eslint-disable
    no-loop-func,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const landingInit = require("./landingInit").default;

const fileInput = (className) =>
  (() => {
    const result = [];
    for (let customInput of Array.from($(className))) {
      const input = $(customInput);
      var content = input.find(".fake-input");

      result.push(
        input.find("input").on("change", function (e) {
          const { files } = e.currentTarget;
          if (!files.length) {
            return;
          }
          return content.text(files[0].name).removeClass("placeholder");
        })
      );
    }
    return result;
  })();

$(function () {
  landingInit();

  fileInput(".custom-file-input");

  const $link = $("p.forgot a");
  $link.click(function (e) {
    e.preventDefault();
    const $copyEmail = $(".copy-email");
    const url =
      $link.attr("href") + "?email=" + encodeURIComponent($copyEmail.val());
    return (window.location.href = url);
  });

  const $scroll = $(".window-scroll");
  const div = $scroll.get(0);
  if (div) {
    let enableButton;
    (enableButton = function () {
      if (div.offsetHeight + div.scrollTop >= div.scrollHeight - 20) {
        return $("#accept-terms-btn").prop("disabled", false);
      }
    })();
    return $scroll.scroll(enableButton);
  }

  const checkAgreement = $("#check-agreement");
  if (checkAgreement.get(0)) {
    checkAgreement.click(function (e) {
      e.preventDefault();
      const agreementCheckbox = $("#agreement-checkbox");
      const checkAgreementNotice = $("#check-agreement-notice");
      if (agreementCheckbox.is(":checked")) {
        checkAgreement.closest("form").submit();
      } else {
        checkAgreementNotice.text("Please check the Terms and Privacy statements to continue");
      }
    });
  }
});
