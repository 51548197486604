// @flow
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring/web.cjs.js";

export default function EmailStep() {
  const style = useSpring({ opacity: 1, from: { opacity: 0 } });

  const [email, setEmail] = useState<string>("");
  const history = useHistory();

  const location = useLocation();
  const error =
    new URLSearchParams(location.search).get("error") ||
    window.loginError ||
    "";

  const onSubmit = useCallback(
    async (e: SyntheticInputEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (email.length > 0) {
        const params = new URLSearchParams();
        params.append("email", email);
        window.localStorage.setItem("lastLoginEmail", email);
        history.push(`/authentication?${params.toString()}`);
      }
    },
    [email, history]
  );

  useEffect(() => {
    const email = window.localStorage.getItem("lastLoginEmail");
    if (error.length === 0 && email && email.length > 0) {
      const params = new URLSearchParams();
      params.append("email", email);
      history.push(`/authentication?${params.toString()}`);
    }
  }, [error, history, email]);

  useEffect(() => {
    const noticeContainer = document.getElementById("notice-container");
    if (!noticeContainer) {
      throw new Error("no notice container");
    }
    noticeContainer.innerText = error;
  }, [error]);

  return (
    <animated.form
      onSubmit={onSubmit}
      className="ui form"
      action="/login"
      method="post"
      style={{
        minWidth: 300,
        ...style,
      }}
    >
      <div className="field ui left icon input">
        <input
          className="form-control copy-email"
          placeholder="email"
          autoFocus
          type="text"
          name="user[email]"
          id="user_email"
          value={email}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) =>
            setEmail(e.currentTarget.value)
          }
        />
        <i className="user icon"></i>
      </div>

      <div className="field">
        <button style={{ cursor: "pointer" }} className="btn btn-blue">
          Login
        </button>
      </div>
    </animated.form>
  );
}
