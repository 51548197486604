// @flow
import React from "react";
import type { IdentityProvider } from "../sso_setup/useIdentityProviders";
import { useSpring, animated } from "react-spring/web.cjs.js";
type SSOAuthenticationProps = {|
  email: string,
  identityProviders: Array<IdentityProvider>,
  toggleAuthenticationType: () => void,
|};

export default function SSOAuthentication({
  email,
  identityProviders,
  toggleAuthenticationType,
}: SSOAuthenticationProps) {
  const style = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.div style={style}>
      {identityProviders.map((idp) => {
        const params = new URLSearchParams();
        params.append("email", email);
        params.append("provider_id", idp.id.toString());

        return (
          <a key={idp.id} href={`/saml?${params.toString()}`}>
            <div key={idp.id} className="field">
              <button
                style={{ marginBottom: 10, cursor: "pointer" }}
                className="btn btn-blue"
              >
                Log in using {idp.name}
              </button>
            </div>
          </a>
        );
      })}

      <p className="forgot">
        <a
          href="#"
          onClick={(e: SyntheticMouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            toggleAuthenticationType();
          }}
        >
          Log in using password
        </a>
      </p>
    </animated.div>
  );
}
